@use '@angular/material' as mat;
@import "./variables";

@mixin theme-reset($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .bg-primary {
    background-color: mat.get-color-from-palette($primary);
    color: mat.get-color-from-palette($primary, default-contrast);
  }
  .bg-accent {
    background-color: mat.get-color-from-palette($primary);
    color: mat.get-color-from-palette($primary, default-contrast);
  }
  .bg-warn {
    background-color: mat.get-color-from-palette($primary);
    color: mat.get-color-from-palette($primary, default-contrast);
  }

  .text-muted {
    color: mat.get-color-from-palette($foreground, secondary-text) !important;
  }

  .secondary-color {
    color: mat.get-color-from-palette($primary, 100);
  }
  .mat-step-header {
    &:hover {
      background-color: $ag-main-background-color;
    }
    font-weight: bold;
    .mat-step-icon {
      &.mat-step-icon-state-number:not(.mat-step-icon-selected) {
        background-color: $main-menu-background-color;
        span {
          color: mat.get-color-from-palette($primary, 500);
        }
      }
      &.mat-step-icon-selected,
      &.mat-step-icon-state-done,
      &.mat-step-icon-state-edit {
        background-color: mat.get-color-from-palette($accent, 500);
        &.mat-step-icon-content {
          span {
            color: $ag-main-background-color !important;
          }
        }
      }
    }
  }

  .mat-step-header {
    .mat-step-label {
      white-space: break-spaces;
      &.mat-step-label-selected,
      &.mat-step-label-done,
      &.mat-step-label-edit {
        color: mat.get-color-from-palette($accent)!important;
      }
    }
  }

  .swiper-pagination-bullet {
    background-color: mat.get-color-from-palette($background, 500);
  }

  .swiper-button-prev,
  .swiper-button-next {
    span {
      color: $ag-main-background-color;
    }
  }

  .border-bottom-mute {
    border-bottom: 1px solid mat.get-color-from-palette($primary, lighter, 0.2);
  }

  .search-dropdown.mat-mdc-menu-panel {
    background: transparent;
  }

  .mat-mdc-snack-bar-container.success {
    background: #388e3c;
  }
  .mat-mdc-snack-bar-container.error {
    background: #e53935;
  }

  .new-price {
    color: mat.get-color-from-palette($warn);
  }
  .primary-text {
    color: mat.get-color-from-palette($primary);
  }

  .horizontal-active-link,
  .app-dropdown .mat-mdc-menu-item.horizontal-active-link {
    color: mat.get-color-from-palette($primary) !important;
  }

  .filter-brands button.selected,
  .filter-buttons button.selected {
    box-shadow: 0px 0px 1px 2px mat.get-color-from-palette($primary);
  }

  /* ngx-pagination */
  .product-pagination .ngx-pagination {
    margin-bottom: 0;
    padding: 14px 10px;
    .current {
      background-color: mat.get-color-from-palette($primary, 200);
    }
    a:hover,
    button:hover {
      background: rgba(mat.get-color-from-palette($primary), 0.2);
      color: mat.get-color-from-palette($foreground, base);
    }
  }

  // admin styles
  .breadcrumb {
    a {
      color: mat.get-color-from-palette($primary);
    }
    .breadcrumb-item + .breadcrumb-item:before {
      color: mat.get-color-from-palette($foreground, text);
    }
  }
  .user-block {
    border-bottom: 1px solid mat.get-color-from-palette($foreground, divider);
  }

  .menu-item .mat-mdc-button:not(.active-link) .menu-icon {
    color: mat.get-color-from-palette($primary);
  }

  .mat-mdc-button {
    &:hover:not(:disabled) {
      color: mat.get-color-from-palette($accent, 500);
      .mat-icon {
        color: mat.get-color-from-palette($accent, 500);
      }
    }
  }

  .mat-mdc-raised-button {
    &:hover:not(:disabled) {
      background-color: mat.get-color-from-palette($accent, 500);
    }
    
  }
  .mat-mdc-raised-button {
    letter-spacing: 3px;
    text-transform: uppercase;
    font-size: 14px;
  }
  .mat-mdc-outlined-button {
    letter-spacing: 3px;
    text-transform: uppercase;
    font-size: 14px;
    border: 2px solid #333 !important;
    &:hover:not(:disabled) {
      color: mat.get-color-from-palette($accent, 500);
      background-color: $ag-main-background-color;
      border: 2px solid mat.get-color-from-palette($accent, 500) !important;
    }
    .mat-mdc-focus-indicator {
      background-color: $ag-main-background-color !important;
    }
    &:active:not(:disabled) {
      background-color: $ag-main-background-color !important;
    }
  }

  .test-with-accent-hover {
    &:hover {
      color: mat.get-color-from-palette($accent, 500);
    }
  }
}