
// here we'll generate any custom classes for colors
@use '@angular/material' as mat;
@mixin color-scheme($name, $color) {
  .#{$name} {
   color: mat.get-color-from-palette($color)!important;
  }
  .background-#{$name}{
    background-color: mat.get-color-from-palette($color)!important;
  }

  .border-#{$name}{
    border-color: mat.get-color-from-palette($color)!important;
  }

  @each $shade in map-keys($color) {
    @if $shade !=contrast {
      .#{$name}-#{$shade} {
        color: mat.get-color-from-palette($color, $shade)!important;
      }
      .background-#{$name}-#{$shade} {
        background-color: mat.get-color-from-palette($color, $shade)!important;
      }
      .border-#{$name}-#{$shade} {
        border-color: mat.get-color-from-palette($color, $shade)!important;
      }
    }
  }
}