@use '@angular/material' as mat;
@import './variables';

@include mat.core();

@import 'theme-reset';
@import'skins/ag-custom';
.app{
    &.ag{
      @import "skins/ag";
      @include color-scheme(color-primary,$ag-mat-primary-color);
      @include color-scheme(color-accent,$ag-mat-accent-color);
      @include color-scheme(color-light,$ag-mat-light-color);
      @include mat.all-component-themes($ag-theme);
      @include theme-reset($ag-theme);
    }
}


