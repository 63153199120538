@use '@angular/material' as mat;
@import "mat-shades";

$ag-font-family: "HelveticaNeue";
$ag-font-family-medium:"HelveticaNeueMedium";
$theme-max-width: 1300px;

$admin-toolbar-height: 56px;
$admin-sidenav-width: 280px;
$sidenav-user-block-height: 156px;

$mat-table-row-even-bg: white;
$mat-table-row-odd-bg: #f5f5f5;

$ag-primary-color:#636569;
$ag-accent-color:#db0032;
$ag-light-color:#f0f0f0;

$ag-typography: mat.define-typography-config($font-family:"HelveticaNeue" );

$ag-gray:map-get($map: $ag-mat-light-color, $key: 500);

$ag-background: #fff;

$header-text-color: #333;
$header-background-color: #fff;
$footer-text-color: #333;
$main-menu-background-color:#f0f0f0;
$ag-main-background-color:white;

$focus-border-color:highlight;
$focus-border-width:1px;

$unread-notifications:#FFE1E1;