@import "variables";
@import "mixins";

.mat-mdc-raised-button:hover,
.mat-mdc-outlined-button:hover,
.mat-mdc-unelevated-button:hover,
.mat-mdc-button:hover {
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  .mat-mdc-focus-indicator {
    display: none;
    visibility: hidden;
  }
}

.cdk-keyboard-focused.mat-mdc-raised-button,
.cdk-keyboard-focused.mat-mdc-button,
.cdk-keyboard-focused.mat-mdc-outlined-button,
.cdk-keyboard-focused.mat-mdc-unelevated-button {
  @include keyboard-focus;
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  .mat-mdc-focus-indicator {
    display: none;
    visibility: hidden;
  }
}

// .mat-form-field.mat-focused {
//   .mat-form-field-wrapper {
//     .mat-form-field-flex {
//       @include keyboard-focus;
//       border-radius: 5px;
//     }
//   }
// }

.cdk-keyboard-focused {
  .mat-sort-header-container {
    margin-left: 6px;
    padding-left: 6px;
    @include keyboard-focus;
  }
}
.cdk-keyboard-focused {
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
  .mat-radio-outer-circle {
    @include keyboard-focus;
  }
}
.cdk-keyboard-focused {
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  .mat-button-wrapper {
    .mat-datepicker-toggle-default-icon {
      @include keyboard-focus;
    }
  }
}
.cdk-keyboard-focused {
  &.mat-calendar-previous-button,
  &.mat-calendar-next-button {
    @include keyboard-focus;
    background: white!important;
  }
}
.cdk-keyboard-focused {
&.mat-calendar-content{
.mat-calendar-body-active{
  @include keyboard-focus;
}
}
}