@import "./variables";

@mixin menu-level-padding(){
    $elem: '';
    @for $i from 2 through 10 {
        $elem: if($i == 2, ".sub-menu", selector-nest($elem, ".sub-menu"));      
        #{$elem + ' .mat-mdc-button'} { 
            padding-left: 16px + (20px * $i); 
        }
    }
}

@mixin admin-menu-level-padding($direction){
    $elem: '';
    @for $i from 2 through 10 {
        $elem: if($i == 2, ".sub-menu", selector-nest($elem, ".sub-menu"));      
        #{$elem + ' .mat-mdc-button'} { 
            @if $direction == "rtl" {
                padding-right: 20px * $i; 
            } 
            @else{
                padding-left: 20px * $i; 
            }
        }
    }
}

@mixin keyboard-focus {
  border-color: $focus-border-color !important;
  box-shadow: 0 0 4px 3px $focus-border-color !important;
  outline: 0px auto -webkit-focus-ring-color !important;
}